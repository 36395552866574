html {
  min-height: calc(100% + env(safe-area-inset-top));
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}

html, body, #root {
  margin: 0;
  height: 100%;
}

body {
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
}

#root {
  background-color: white;
}

/* https://www.1001fonts.com/actonia-personal-use-font.html */
@font-face {
  font-family: 'actonia';
  src: local('actonia'), url(./fonts/actonia.ttf) format('truetype');
}

body, button, input, textarea {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
  font-weight: 600;
  color: #57827a;
}
a:visited {
  color: #57827a;
}

select {
  color: black;
}
input, textarea {
  -webkit-appearance: none;
}